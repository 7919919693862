import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import List from "list.js";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addApple,
  deleteAppleDefective,
  exportApple,
  importApple,
  soldApple,
} from "../../store/appleid/actions";
import AddNew from "./Global/AddNew";
import TableItem from "./Global/TableItem";
import AppleExport from "./Global/AppleExport";
import AppleExportQr from "./Global/AppleExportQr";
import AppleFixAccounts from "./Global/AppleFixAccounts";

const DefectiveId = () => {
  //   const [modal_list, setmodal_list] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_export, setmodal_export] = useState(false);
  const [modal_export_qr, setmodal_export_qr] = useState(false);
  const [modal_fix_accounts, setmodal_fix_accounts] = useState(false);
  //   const [files, setFiles] = React.useState(null);
  //   const [base64Image, setBase64Image] = useState("");
  const [editItem, setEditItem] = React.useState({});
  const [filteredData, setFilteredData] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    // If the checkbox is checked, add the value to the array
    if (checked) {
      setCheckedItems((prevState) => [...prevState, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setCheckedItems((prevState) =>
        prevState.filter((item) => item !== value)
      );
    }
  };

  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const fileInputRef = useRef(null);

  //   const imageUploadChange = (e) => {
  //     const file = e.target.files[0];

  //     if (file) {
  //       const reader = new FileReader();

  //       reader.onloadend = () => {
  //         setBase64Image(reader.result);
  //       };

  //       reader.readAsDataURL(file);
  //       setFiles(file);
  //     }

  //     //   const src = URL.createObjectURL(e.target.files[0]);
  //   };

  document.title = "Apple ID  | Mega ID";

  function tog_center() {
    setmodal_center(!modal_center);
    setEditItem({});
  }

  function tog_export() {
    setmodal_export(!modal_export);
  }

  function tog_export_qr() {
    setmodal_export_qr(!modal_export_qr);
  }

  function tog_fix_accounts() {
    setmodal_fix_accounts(!modal_fix_accounts);
  }

  const handleEdit = (item) => {
    setEditItem(item);
    setmodal_center(!modal_center);
  };

  const handleDelete = (itemId) => {
    dispatch(deleteAppleDefective({ id: itemId }));
  };

  const handleSold = (itemId) => {
    dispatch(soldApple({ id: itemId }));
  };

  const handleSearch = (text) => {
    const newQuery = text.target.value;

    const filtered = state.IDS.defective.filter((id) => id.id == newQuery);
    setFilteredData(filtered);
  };

  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleExportClick = () => {
    dispatch(exportApple(checkedItems));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Do something with the selected file

    dispatch(importApple({ file: file }));
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.IDS.defective.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(state.IDS.defective.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const setPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10;
    const maxVisiblePages = Math.min(totalPages, maxPageNumbers);

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Mega" breadcrumbItem="Defective Apple ID" />

          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove</h4>
                </CardHeader> */}

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {/* <Button
                            color="success"
                            className="add-btn"
                            onClick={() => tog_center()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Apple ID
                          </Button> */}
                          {/* <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          /> */}
                          {/* <Button
                            color="success"
                            className="add-btn"
                            onClick={() => handleImportClick()}
                            id="create-btn"
                          >
                            <i className="ri-arrow-down-line align-bottom me-1"></i>{" "}
                            Import
                          </Button> */}
                          {/* <Button
                            color="success"
                            className="add-btn"
                            // onClick={() => handleExportClick()}
                            onClick={() => tog_export()}
                            id="create-btn"
                          >
                            <i className="ri-arrow-up-line align-bottom me-1"></i>{" "}
                            Export
                          </Button> */}
                          {/* <Button
                            color="success"
                            className="add-btn"
                            // onClick={() => handleExportClick()}
                            onClick={() => tog_export_qr()}
                            id="create-btn"
                          >
                            <i className="ri-arrow-up-line align-bottom me-1"></i>{" "}
                            Export as QR
                          </Button> */}

                          {/* <Button
                            color="success"
                            className="add-btn"
                            // onClick={() => handleExportClick()}
                            onClick={() => tog_fix_accounts()}
                            id="create-btn"
                          >
                            <i className="ri-error-warning-line align-bottom me-1"></i>{" "}
                            Fix Accounts
                          </Button> */}
                          {/* <Button
                            color="soft-danger"
                            // onClick="deleteMultiple()"
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </Button> */}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => handleSearch(e)}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            {/* <th scope="col" style={{ width: "50px" }}>
                              {/* <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th> */}
                            <th className="sort" data-sort="customer_name">
                              ID
                            </th>
                            <th className="sort" data-sort="email">
                              Email
                            </th>
                            <th className="sort" data-sort="phone">
                              Creation Date
                            </th>

                            {/* <th className="sort" data-sort="status">
                              Availability
                            </th> */}
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {filteredData.length === 0
                            ? currentItems.map((apple) => (
                                <TableItem
                                  apple={apple}
                                  handleEdit={handleEdit}
                                  handleSold={handleSold}
                                  handleDelete={handleDelete}
                                  handleCheckboxChange={handleCheckboxChange}
                                  checkedItems={checkedItems}
                                />
                              ))
                            : filteredData.map((apple) => (
                                <TableItem
                                  apple={apple}
                                  handleEdit={handleEdit}
                                  handleSold={handleSold}
                                  handleDelete={handleDelete}
                                  handleCheckboxChange={handleCheckboxChange}
                                  checkedItems={checkedItems}
                                />
                              ))}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      {/* <div
                        className="btn-group me-2"
                        role="group"
                        aria-label="First group"
                      >
                        <button type="button" className="btn btn-primary">
                          1
                        </button>
                        <button type="button" className="btn btn-primary">
                          2
                        </button>
                        <button type="button" className="btn btn-primary">
                          3
                        </button>
                        <button type="button" className="btn btn-primary">
                          4
                        </button>
                      </div> */}

                      <div className="pagination-wrap hstack gap-2">
                        {/* <ul className="pagination listjs-pagination mb-0"> */}
                        <div className="d-flex justify-content-end">
                          <div
                            className="btn-group me-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button
                              className="page-item pagination-prev  btn btn-primary"
                              onClick={prevPage}
                              type="button"
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            {/* <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setPage(1)}
                              disabled={currentPage === 1}
                            >
                              1
                            </button> */}
                            {getPageNumbers().map((pageNumber) => (
                              <button
                                type="button"
                                className="btn btn-primary"
                                key={pageNumber}
                                disabled={pageNumber === currentPage}
                                onClick={() => setPage(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            ))}
                            {/* <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setPage(totalPages)}
                              disabled={currentPage === totalPages}
                            >
                              {totalPages}
                            </button> */}
                            <button
                              disabled={currentPage === totalPages}
                              className="page-item pagination-prev  btn btn-primary"
                              type="button"
                              onClick={nextPage}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                        {/* </ul> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {modal_center ? (
            <AddNew
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
              data={editItem}
            />
          ) : null}

          {modal_export ? (
            <AppleExport
              modal_export={modal_export}
              setmodal_export={setmodal_export}
              tog_export={tog_export}
            />
          ) : null}

          {modal_export_qr ? (
            <AppleExportQr
              modal_export_qr={modal_export_qr}
              setmodal_export_qr={setmodal_export_qr}
              tog_export_qr={tog_export_qr}
            />
          ) : null}

          {modal_fix_accounts ? (
            <AppleFixAccounts
              modal_fix_accounts={modal_fix_accounts}
              setmodal_fix_accounts={setmodal_fix_accounts}
              tog_fix_accounts={tog_fix_accounts}
            />
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DefectiveId;
